<template>
    <v-card class="rounded-xl">
        <v-card-text class="d-flex flex-column align-center pb-2" style="gap: 12px; padding: 12px; ">
            <div class="task-date">{{ task_time }}</div>
            <div class="task-type" :class="$vuetify.rtl ? 'pb-2' : ''">{{ $tr(task_visitation_type) }}</div>
        </v-card-text>
        <v-card-text class="py-0">
            <v-divider></v-divider>
        </v-card-text>
        <v-card-text class="py-2 pt-0">
            <v-container fluid class="px-0">
                <v-row class="m-between px-1">
                    <v-chip v-if="city">{{ $tr("City") }}: {{ city }}</v-chip>
                    <v-chip v-if="region">{{ $tr("Region") }}: {{ region }}</v-chip>
                    <v-chip v-if="pharmacy">{{ $tr("Pharmacy") }}: {{ pharmacy }}</v-chip>
                    <v-chip v-if="manufacturer">{{ $tr("Manufacturer") }}: {{ manufacturer }}</v-chip>
                    <v-chip v-if="position_url" color="success" @click="$show_location(position_url)">{{
                        $tr("Position")
                    }}</v-chip>
                    <v-chip v-if="comments">{{ $tr("Comments") }}: {{ comments }}</v-chip>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: {
        task: null,
    },
    computed: {
        task_time() {
            if (!this.task?.sr_pharmacy_time) {
                return "";
            }
            const time = new Date(this.task.sr_pharmacy_time).toLocaleString();
            return time;
        },
        city() {
            const city = this.task?.region?.city?.name;
            return city ? this.$cap(city) : "";
        },
        region() {
            const region = this.task?.region?.name;
            return region ? this.$cap(region) : "";
        },
        pharmacy() {
            const pharmacy = this.task?.sr_pharmacy_pharmacy_name;
            return pharmacy ? this.$cap(pharmacy) : "";
        },
        task_visitation_type() {
            if (this.task?.sr_pharmacy_vesitation_type) {
                return this.$cap(this.task?.sr_pharmacy_vesitation_type);
            } else {
                return "";
            }
        },
        manufacturer() {
            if (this.task?.sr_pharmacy_manufacturer?.name) {
                return this.$cap(this.task?.sr_pharmacy_manufacturer?.name);
            } else {
                return "";
            }
        },
        position_url() {
            if (!this.task?.sr_pharmacy_position_latitude || !this.task.sr_pharmacy_position_longitude) {
                return "";
            }
            return `https://www.google.com/maps/search/?api=1&query=${this.task.sr_pharmacy_position_latitude},${this.task.sr_pharmacy_position_longitude}`;
        },

        comments() {
            if (!this.task?.sr_pharmacy_comment) {
                return "";
            }
            return this.task?.sr_pharmacy_comment;
        },
    },
    data() {
        return {
            delete_dialog: {
                show: false,
            },
        };
    },
    methods: {
        show_delete_dialog() {
            this.delete_dialog.show = true;
        },
    },
};
</script>

<style lang="sass">
.task-title
    margin: 0px
    font-size: 24px
    font-weight: 800
    word-spacing: 4px
.task-type
    margin: 0px
    font-size: 14px
    font-weight: 400
    word-spacing: 4px
.task-date
    margin: 0px
    font-size: 18px
    font-weight: 700
    word-spacing: 4px

.m-between
    & *
        margin: 4px
.delete-icon
    margin-bottom: 1px
</style>
