<template>
    <v-container fluid>
        <vue-loadmore
            style="width: 100%; direction: ltr; margin-bottom: 40px"
            :on-refresh="(done) => refresh_sr_tasks(done, true)"
            :on-loadmore="load_more"
            :finished="no_more_data"
            :pulling-text="$tr('Refresh')"
            :loosing-text="$tr('Refresh')"
            :refresh-text="`${$tr('Loading')}`"
            :loading-text="`${$tr('Loading')}`"
            :finished-text="$tr('Done')"
            :success-text="$tr('Done')"
        >
            <pull-to-refresh-banner ref="banner"></pull-to-refresh-banner>
            <v-fade-transition group class="d-flex align-start flex-direction-row">
                <v-card color="basil" :key="'main_card'" flat class="transparent px-0" width="100%" v-show="show_tasks">
                    <v-card-text class="pt-0">
                        <v-fade-transition group>
                            <v-row class="px-2 pt-0 pb-4" v-for="(task, index) in tasks" :key="index">
                                <task-card @refresh="(done) => refresh_sr_tasks(done, true)" :task="task" />
                            </v-row>
                            <v-row
                                    key="LoadMore"
                            >
                                <v-btn
                                    v-show="!loading_more && !no_more_data"
                                    class="primary rounded-lg"
                                    width="100%"
                                    elevation="0"
                                    @click="load_more"
                                >
                                    Load More
                                </v-btn>
                            </v-row>
                        </v-fade-transition>
                    </v-card-text>
                </v-card>

                <v-container v-show="show_skeleton" :key="'skeleton'" class="px-4" fluid>
                    <v-row class="mb-2" :key="'skeleton-1'">
                        <v-skeleton-loader :loading="true" width="100%" type="article, actions"></v-skeleton-loader>
                    </v-row>
                    <v-row class="mb-2" :key="'skeleton-2'">
                        <v-skeleton-loader :loading="true" width="100%" type="article, actions"></v-skeleton-loader>
                    </v-row>
                    <v-row class="mb-2" :key="'skeleton-3'">
                        <v-skeleton-loader :loading="true" width="100%" type="article, actions"></v-skeleton-loader>
                    </v-row>
                    <v-row class="mb-2" :key="'skeleton-4'">
                        <v-skeleton-loader :loading="true" width="100%" type="article, actions"></v-skeleton-loader>
                    </v-row>
                    <v-row class="mb-2" :key="'skeleton-5'">
                        <v-skeleton-loader :loading="true" width="100%" type="article, actions"></v-skeleton-loader>
                    </v-row>
                </v-container>
            </v-fade-transition>
        </vue-loadmore>
    </v-container>
</template>

<script lang="js">

import TaskCard from "../Components/HistoryTaskCard/Index.vue";
import AddTaskDialog from "../Components/AddTaskDialog/Index.vue";

export default {
    components: {
        TaskCard,
        AddTaskDialog,
        PullToRefreshBanner: () => import("../../Admin/Home/components/pullToRefreshBanner/Index.vue"),
    },
    data(){
        return {
            tasks: [],
            total_data_length: null,
            items_per_page: 20,
            show_skeleton: false,
            show_tasks: true,
            loading_more: false,
        }
    },
    computed: {
        no_more_data(){
            console.log("no more data", this.total_data_length <= this.tasks?.length)
            if(this.total_data_length === null){
                return false
            }else{
                return this.total_data_length <= this.tasks?.length
            }
        }
    },
    methods:{

        async load(now = false) {
            try {
                const sr_tasks = (
                    await this.$api.post("sr/tasks/fetch/paginate", {
                        skip: this.tasks?.length || 0,
                        take: this.items_per_page,
                    }, {
                        sinceMins: 60*24*3,
                        now: now,
                    })
                ).data;
                this.total_data_length = sr_tasks.total;
                this.tasks.push(...sr_tasks.results);
            } catch (error) {
                console.log(error);
            }
        },


        async set_loading(){
            this.show_tasks = false;
            this.show_skeleton = false;
            await this.$sleep(200);
            this.show_skeleton = true;
        },
        async clear_loading() {
            this.show_skeleton = false;
            this.show_tasks = false;
            await this.$sleep(100);
            this.show_tasks = true;
        },

        async refresh_sr_tasks(done, now = false){
            await this.set_loading();
            this.tasks = [];
            this.total_data_length = null;
            await this.load(now)
            await this.clear_loading();
            done?.()
        },
        async load_more(done){
            this.loading_more = true;
            await this.load(false)
            this.loading_more = false;
            done?.()
        }
    },
    async mounted(){
        await this.refresh_sr_tasks(null, false);
         this.$refs.banner.view_banner();
        },
}
</script>
